<template>
  <a-layout id="main-layout" style="min-height: 100vh">
    <a-layout-sider v-model="collapsed" collapsible width="260">
      <router-link to="/" class="logo">
        <span v-show="!collapsed" :style="{ marginRight: '10px' }">
          Carplus
        </span>
      </router-link>
      <a-menu
        mode="inline"
        theme="dark"
        :selected-keys="menuItems"
        :open-keys.sync="subMenus"
        @click="handleClick"
      >
        <a-sub-menu v-if="userIsAdmin || userIsSupport" key="requests-panel">
          <span slot="title">
            <a-icon type="copy" class="bigger-icon" />
            <span>Заявки</span>
          </span>
          <a-menu-item key="requests">
            <span>Все</span>
          </a-menu-item>
        </a-sub-menu>

        <a-sub-menu v-if="userIsAdmin" key="cars-panel">
          <span slot="title">
            <a-icon type="car" class="bigger-icon" />
            <span>Машины</span>
          </span>
          <a-menu-item key="all">
            <span>Все</span>
          </a-menu-item>
        </a-sub-menu>

        <a-sub-menu v-if="userIsAdmin" key="filters-panel">
          <span slot="title">
            <a-icon type="filter" class="bigger-icon" />
            <span>Фильтры</span>
          </span>
          <a-menu-item key="filters-all">
            <span>Все</span>
          </a-menu-item>
          <a-menu-item key="filters-values">
            <span>Опции</span>
          </a-menu-item>
          <a-menu-item key="makes">
            <span>Марки</span>
          </a-menu-item>
        </a-sub-menu>

        <a-menu-divider />

        <a-sub-menu v-if="userIsAdmin || userIsSupport" key="users-panel">
          <span slot="title">
            <a-icon type="user" class="bigger-icon" />
            <span>Пользователи</span>
          </span>
          <a-menu-item v-if="userIsAdmin" key="sessions">
            <span>Сессии</span>
          </a-menu-item>
          <a-menu-item key="clients">
            <span>Клиенты</span>
          </a-menu-item>
          <a-menu-item v-if="userIsAdmin" key="users">
            <span>Сотрудники</span>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="header">
        <a-dropdown>
          <a
            href="#"
            class="ant-dropdown-link"
            :style="{ display: 'flex', alignItems: 'center' }"
          >
            <a-icon type="user" class="user-icon" />
            {{ userFullName | capitalize }}
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a
                @click.prevent="
                  $router
                    .push({
                      name: 'users-edit',
                      params: { id: user.id },
                    })
                    .catch(_err => {})
                "
              >
                Профиль
              </a>
            </a-menu-item>
            <a-menu-item>
              <a @click.prevent="logout">Выйти</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-layout-header>
      <a-layout-content class="content">
        <div v-if="$route.name === 'dashboard'" class="init-title">
          Добро пожаловать!
        </div>
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import {
  AUTH_USER,
  LOGOUT_USER,
  AUTH_USER_IS_ADMIN,
  AUTH_USER_IS_SUPPORT,
} from '@/store/types'

export default {
  name: 'Main',

  data: () => ({
    subMenus: [],
    collapsed: false,
  }),

  computed: {
    user() {
      return this.$store.getters[AUTH_USER]
    },
    userIsAdmin() {
      return this.$store.getters[AUTH_USER_IS_ADMIN]
    },
    userIsSupport() {
      return this.$store.getters[AUTH_USER_IS_SUPPORT]
    },
    menuItems() {
      return this.$route.meta.parents
        ? [...this.$route.meta.parents, this.$route.name]
        : [this.$route.name]
    },
    userFullName() {
      return `${this.user.first_name} ${this.user.last_name}`
    },
  },

  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(route) {
        this.subMenus = route.meta.parents
          ? [...route.meta.parents]
          : [route.name]
      },
    },
  },

  methods: {
    logout() {
      this.$store.dispatch(LOGOUT_USER)
    },
    handleClick({ key }) {
      this.$route.name !== key &&
        // eslint-disable-next-line no-unused-vars
        this.$router.push({ name: key }).catch(_err => {})
    },
  },
}
</script>

<style lang="scss" scoped>
#main-layout .bigger-icon {
  font-size: 18px;
}

.logo {
  display: block;
  height: 64px;
  line-height: 64px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #019ccd;
  text-decoration: none;
}

.header {
  padding: 0 30px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
}

.user-icon {
  font-size: 18px;
  margin-right: 5px;
}

.content {
  margin: 20px;
  padding: 15px;
  background: #fff;
  min-height: unset;
}

.init-title {
  height: 100%;
  display: flex;
  font-size: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
</style>
